import React, { useState } from 'react';

// chakra imports
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { SidebarContent } from 'components/sidebar/components/Content';
import { renderThumb, renderTrack, renderView } from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ReleaseNotesPopup } from 'components/releaseNotesPopup/ReleaseNotesPopup';
import { useTranslation } from 'react-i18next';

export interface SidebarProps {
  routes: RoutesType[];
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;

  isFloatingAnchorIcon?: boolean;

  [x: string]: any;
}

export function Sidebar(props: SidebarProps) {
  const { routes, onClickButton } = props;
  const { t } = useTranslation();

  const variantChange = '0.2s linear';
  const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  // Chakra Color Mode
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const sidebarMargins = '0px';

  const [isCollapsed, setIsCollapsed] = useState(false);

  // SIDEBAR
  return (
    <Flex>
      <ReleaseNotesPopup />

      <Box minH="100%">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={isCollapsed ? '77px' : '300px'}
          transitionProperty={'width'}
          transitionDuration={'0.2s'}
          h="100vh"
          m={sidebarMargins}
          minH="100%"
          overflowX="hidden"
          overflowY="auto"
          boxShadow={shadow}
        >
          <Scrollbars autoHide renderTrackVertical={renderTrack} renderThumbVertical={renderThumb}>
            <SidebarContent
              routes={routes}
              onClickButton={onClickButton}
              isCollapsed={isCollapsed}
              isCollapsable={true}
              onCollapseClick={() => setIsCollapsed(!isCollapsed)}
            />
          </Scrollbars>
        </Box>
      </Box>
    </Flex>
  );
}
