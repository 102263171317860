import { useCallback, useEffect, useState } from 'react';
import { Box, useDisclosure, Flex, Show } from '@chakra-ui/react';
import { AdminNavbar } from 'components/navbar/NavbarAdmin';
import { Sidebar } from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { Navigate, Route, Routes, useNavigate, matchPath, useLocation } from 'react-router-dom';
import { adminCreateButtonRoute, useAppRoutes } from 'routes';
import { getRoutesFromRouteTypes } from '../utils/routes';
import { useTranslation } from 'react-i18next';
import { HelpPanel } from '../../components/HelpPanel/HelpPanel';
import { useTypedSelector } from '../../store';

const getRoute = () => {
  return window.location.pathname !== '/full-screen-maps';
};

const getCurrentRouteByPathValue = (routes: RoutesType[], path: string): RoutesType => {
  const currentRoute = routes.find(route => route.path === path);
  if (currentRoute) {
    return currentRoute;
  }

  return routes[0];
};

export function AdminLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const ticketType = useTypedSelector(state => state.helpTools.selectedHelpTicketType);
  const isCollapsed = useTypedSelector(state => state.helpTools.isHelpTicketPanelCollapsed);
  const isOpen = !!ticketType;

  const routesAdmin = useAppRoutes();

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const onClickSidebarButton = useCallback(() => {
    navigate(adminCreateButtonRoute);
  }, [navigate]);

  const [currentRoute, setCurrentRoute] = useState<RoutesType>(routesAdmin[0]);
  useEffect(() => {
    const matchedRoute = routesAdmin.find(route => matchPath(route.path, location.pathname));
    if (matchedRoute) {
      setCurrentRoute(getCurrentRouteByPathValue(routesAdmin, matchedRoute.path));
    } else {
      navigate(window.location.pathname);
    }
  }, [location.pathname, navigate, routesAdmin]);

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Flex>
          <Sidebar routes={routesAdmin} onClickButton={onClickSidebarButton} {...rest} />

          <Box
            className="root-scrollable-container"
            display={'flex'}
            flexDirection="column"
            float="right"
            height="100vh"
            overflow="auto"
            maxHeight="99%"
            w="100%"
            maxWidth={{ base: '100%' }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box zIndex={3} position="relative">
              <AdminNavbar
                onOpen={onOpen}
                logoText={'Horizon UI Dashboard PRO'}
                brandText={t(currentRoute.translatedName)}
                secondary={currentRoute.secondary || false}
                message={currentRoute.name}
                routes={routesAdmin}
                onClickButton={onClickSidebarButton}
                fixed={fixed}
                {...rest}
              />
            </Box>

            <Flex flex={1} pt="0 !important" width="100%" maxW={isOpen && !isCollapsed ? '100vw' : undefined}>
              {getRoute() && (
                <Box mx="auto" p={{ base: '10px', md: '30px' }} pt="0 !important" flex={1} width="100%">
                  <Routes>
                    {getRoutesFromRouteTypes(routesAdmin)}
                    <Route path="/" element={<Navigate to={'home'} />} />
                  </Routes>
                </Box>
              )}

              <HelpPanel />
            </Flex>
          </Box>
        </Flex>
      </SidebarContext.Provider>
    </Box>
  );
}
