import React from 'react';
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'store';

interface HeaderLinksProps {
  secondary: boolean;
  routes: RoutesType[];
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
}

export function AdminNavbarLinks(props: HeaderLinksProps) {
  const { secondary, routes, onClickButton } = props;

  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { user, isAuthenticated, logout } = useAuth0();
  const me = useTypedSelector(state => state.auth.user);
  const { t } = useTranslation();

  return (
    <Flex
      w={{ md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="3px"
      pl="16px"
      gap="10px"
      borderRadius="30px"
    >
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon h="18px" w="18px" color={navbarIcon} as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />
      </Button>

      {user && (
        <>
          <Flex direction="column" me="6px">
            <Text fontSize="14px" fontWeight={700} h="18px" lineHeight="18.23px">
              {isAuthenticated && (me?.name ?? user.name)}
            </Text>
            <Text fontSize="14px" fontWeight={500} color="#A3AED0" h="18px" lineHeight="18.23px">
              {isAuthenticated && (me?.email ?? user.email)}
            </Text>
          </Flex>

          <Menu>
            <MenuButton p="0px">
              <Avatar
                _hover={{ cursor: 'pointer' }}
                color="white"
                name={isAuthenticated && !user.picture ? me?.name ?? user.name : ' '}
                bg="#11047A"
                bgImage={(isAuthenticated && user.picture) || undefined}
                bgSize="contain"
                size="sm"
                w="40px"
                h="40px"
              />
            </MenuButton>
            <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px">
              <Flex flexDirection="column" p="10px">
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                  onClick={() => logout()}
                >
                  <Text fontSize="sm">{t('logout')}</Text>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  );
}

AdminNavbarLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
